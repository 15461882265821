import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import Search from '@/components/molecules/Search'
import Menu from '@/components/atoms/Menu'
import useMatchMedia from '@/hooks/useMatchMedia'

import { FilterTrait } from '@/components/organisms/CommercialFilters/types'
import useQueryParams from '@/hooks/useQueryParams'
import { URL_SEARCH_PARAMS } from '@/helpers/consts'
import { useResidentialFilters } from './useResidentialFilters'
import { ResidentialMenuVariant } from './types'
import * as styles from './styles.module.css'
import { filterItems } from './helpers'

interface Props {
	properties: ResidentialPropertyType[]
	submitFilters: (newProperties: ResidentialPropertyType[]) => void
	children: React.ReactNode
}

function ResidentialFilters({ properties, submitFilters, children }: Props) {
	const {
		activeMenu,
		openMenu,
		closeMenu,
		handleMenuSubmitClick,
		getMenuTitle,
		renderMenuContent,
		filteredItemsCount,
		handleSearchChange,
		handleSearchSubmit,
		handleUrlFilter,
		filters,
		resetFilters,
	} = useResidentialFilters(properties, submitFilters)
	const isDesktop = useMatchMedia('(min-width: 992px)')
	const stateParam = useQueryParams(URL_SEARCH_PARAMS.STATE)
	const [isInitStateValue, handleInitStateValue] = useState(false)
	const menuItems: { title: string; variant: ResidentialMenuVariant }[] = [
		{ title: 'State', variant: FilterTrait.State },
		{ title: 'City', variant: FilterTrait.City },
	]

	useEffect(() => {
		if (stateParam) {
			handleUrlFilter(FilterTrait.State, stateParam)
			handleInitStateValue(true)
		}
	}, [stateParam])

	useEffect(() => {
		if (isInitStateValue) {
			resetFilters()
			submitFilters(filterItems(properties, filters))
		}
		return () => {
			handleInitStateValue(false)
		}
	}, [isInitStateValue])

	return (
		<section>
			<div className={clsx('container', styles.wrapper)}>
				<div className={styles.searchWrapper}>
					<div>{isDesktop && children}</div>
					<Search onChange={handleSearchChange} onSubmit={handleSearchSubmit} value={filters.search} />
				</div>
				<div className={styles.checkboxFilters}>
					<ul>
						{menuItems.map(({ title, variant }) => (
							<li key={title}>
								<button className={styles.filterBtn} onClick={openMenu(variant)}>
									{title}
									<i
										className={clsx(styles.arrowIcon, {
											[styles.active]: activeMenu === variant,
										})}
									/>
								</button>
								<Menu
									open={activeMenu === variant}
									onClose={closeMenu}
									title={getMenuTitle(variant)}
									buttonProps={{
										primary: { label: `Filter (${filteredItemsCount})`, onClick: handleMenuSubmitClick },
										secondary: { label: 'Cancel', onClick: closeMenu },
									}}
								>
									{renderMenuContent(variant)}
								</Menu>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	)
}

export default ResidentialFilters

import React, { ChangeEvent, useCallback, useEffect, useState, useMemo } from 'react'

import CheckboxItem from '@/components/molecules/CheckboxItem'
import useMatchMedia from '@/hooks/useMatchMedia'
import useScrollLock from '@/hooks/useScrollLock'

import { FilterTrait } from '@/components/organisms/CommercialFilters/types'
import { ResidentialCheckboxFilterTrait, ResidentialFilterType, ResidentialMenuVariant } from './types'
import { filterItems, getAvailableFilters } from './helpers'

export const useResidentialFilters = (
	items: ResidentialPropertyType[],
	submitFilters: (newProperties: ResidentialPropertyType[]) => void
) => {
	const [filters, setFilters] = useState<ResidentialFilterType>(getAvailableFilters(items))
	const [activeMenu, setActiveMenu] = useState<ResidentialMenuVariant | null>(null)
	const { lockScroll, unlockScroll } = useScrollLock()
	const isMobile = useMatchMedia('(max-width: 991px)')
	const filteredItems = useMemo(() => filterItems(items, filters), [filters])
	const filteredItemsCount = filteredItems.length

	useEffect(() => {
		const submit = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				submitFilters(filterItems(items, filters))
			}
		}
		window.addEventListener('keydown', submit)
		return () => window.removeEventListener('keydown', submit)
	}, [filters])

	const resetFilters = () => {
		setFilters(getAvailableFilters(items))
	}

	const handleSearchChange = (value: string) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[FilterTrait.Search]: value,
		}))
	}

	const handleSearchSubmit = useCallback(() => {
		submitFilters(filterItems(items, filters))
	}, [filters, items])

	const handleCheckboxChange = useCallback(
		(trait: ResidentialCheckboxFilterTrait) => (e: ChangeEvent<HTMLInputElement>) => {
			setFilters((prevFilters) => {
				const idx = prevFilters[trait].findIndex((el) => el.label === e.target.name)
				if (idx >= 0) {
					const newItem = { ...prevFilters[trait][idx], checked: e.target.checked }
					const newState = { ...prevFilters }
					newState[trait][idx] = newItem
					return newState
				}
				return prevFilters
			})
		},
		[]
	)

	const handleUrlFilter = (variant: ResidentialCheckboxFilterTrait, value: string) => {
		setFilters((prevFilters: ResidentialFilterType) => {
			const idx = prevFilters[variant].findIndex((el) => el.label === value)
			if (idx >= 0) {
				const newItem = { ...prevFilters[variant][idx], checked: true }
				const newState = { ...prevFilters }
				newState[variant][idx] = newItem
				return newState
			}
			return prevFilters
		})
	}

	const openMenu = (menuVariant: ResidentialMenuVariant) => () => {
		setActiveMenu(menuVariant)
		if (isMobile) {
			lockScroll()
		}
	}

	const closeMenu = () => {
		setActiveMenu(null)
		if (isMobile) {
			unlockScroll()
		}
	}

	const handleMenuSubmitClick = () => {
		submitFilters(filterItems(items, filters))
		closeMenu()
	}

	const getMenuTitle = useCallback(
		(variant: ResidentialMenuVariant) =>
			({
				[FilterTrait.City]: 'Cities',
				[FilterTrait.State]: 'States',
			}[variant]),
		[]
	)

	const renderMenuContent = useCallback(
		(variant: FilterTrait): React.ReactElement | React.ReactElement[] | null => {
			switch (variant) {
				case FilterTrait.City:
				case FilterTrait.State:
					return filters[variant].map(({ label, checked }) => (
						<CheckboxItem
							key={label}
							label={label}
							name={label}
							checked={checked}
							onChange={handleCheckboxChange(variant)}
						/>
					))
				default:
					return null
			}
		},
		[filters, handleCheckboxChange]
	)

	return {
		activeMenu,
		openMenu,
		closeMenu,
		handleMenuSubmitClick,
		getMenuTitle,
		renderMenuContent,
		handleCheckboxChange,
		filteredItemsCount,
		handleSearchChange,
		handleSearchSubmit,
		handleUrlFilter,
		filters,
		resetFilters,
	}
}
